import axios from 'axios'

// initial state
const state = () => ({
    cancerregistryentries: [],
    ICDVersions: []
})

// getters
const getters = {
  cancerregistryentries: ({ cancerregistryentries }) => cancerregistryentries,
  cancerregistryCount: ({ cancerregistryentries }) => cancerregistryentries.length,
  ICDVersions: ({ ICDVersions }) => ICDVersions,
}

// actions
const actions = {
    async getCancerregistryEntries({ commit }, sent) {
      const entries = (await axios.get('/cancerregistry?sent=' + (sent ? 1 : 0 ))).data
      commit('setCancerregistryEntries', entries)
    },
    async getICDVersions({ commit }) {
      const versions = (await axios.get('/meta/cancerregistry/icdversions')).data
      commit('setICDVersions', versions)
    },
    async getCancerregistryEntriesByFilters({ commit }, filters) {
      let urlString = '/cancerregistry?sent=' + (filters.sent ? 1 : 0 )

      if(filters.dateFrom && filters.dateTo) {
        urlString += '&date_from=' + filters.dateFrom
        urlString += '&date_to=' + filters.dateTo
      }
      if(filters.type) {
        urlString += '&type=' + filters.type
      }
      const entries = (await axios.get(urlString)).data
      commit('setCancerregistryEntries', entries)
    },
    async generateAllXml(state, patient_id) {
      try {
        const result = await axios.post('/cancerregistry/patient/' + patient_id)
        return result.data
      }
      catch (e) {
        return false
      }
    },
    async generateDiagnosisXml(state, diagnosis) {
      try {
        await axios.post('/cancerregistry/', { type: 'diagnosis', diagnosis: diagnosis })
      }
      catch (e) {
        return false
      }
      return true
    },
    async generateTumorconferenceXml(state, diagnosis) {
      try {
        await axios.post('/cancerregistry/', { type: 'tumorconference', diagnosis: diagnosis })
      }
      catch (e) {
        return false
      }
      return true
    },
    async generateTherapyOPXml(state, therapy) {
      try {
        await axios.post('/cancerregistry/', { type: 'therapy_op', therapy: therapy })
      }
      catch (e) {
        return false
      }

      return true
    },
    async generateSystemTherapyXml(state, therapy) {
        try {
            await axios.post('/cancerregistry/', { type: 'systemtherapy', therapy: therapy })
        }
        catch (e) {
            return false
        }

        return true
    },
    async generateRadioTherapyXml(state, therapy) {
      try {
        await axios.post('/cancerregistry/', { type: 'radio', therapy: therapy })
      }
      catch (e) {
        return false
      }

      return true
    },
    async generateTherapyXml(state, therapy) {
      try {
        await axios.post('/cancerregistry/', { type: 'therapy', therapy: therapy })
      }
      catch (e) {
        return false
      }

      return true
    },
    async generateDeathXml(state, patient) {
      try {
        await axios.post('/cancerregistry/', { type: 'death', patient: patient })
      }
      catch (e) {
        return false
      }
      return true
    },
}

// mutations
const mutations = {
  setCancerregistryEntries(state, cancerregistryentries) {
    state.cancerregistryentries = cancerregistryentries
  },
  setICDVersions(state, versions) {
    state.ICDVersions = versions
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
