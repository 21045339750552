import { createRouter, createWebHistory } from 'vue-router'

function loadView(view) {
  return () => import(`./${view}.vue`);
}

const routes = [
  {
    path: '',
    redirect: () => {
      return { name: 'dashboard' }
    }
  },
  {
    path: '/app',
    name: 'app',
    meta: { auth: true },
    component: loadView('App'),
    children: [
      {
        path: 'about',
        name: 'about',
        component: loadView('pages/About'),
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: loadView('pages/Dashboard'),
        meta: {
          auth: {
            roles: ['admin', 'dacy_user']
          }
        }
      },
      {
        path: 'setting',
        name: 'setting',
        component: loadView('pages/Setting'),
        meta: {
          auth: {
            roles: ['admin']
          }
        }
      },
      {
        path: 'cancerregistry',
        name: 'cancerregistry',
        component: loadView('pages/CancerRegistryDashboard'),
        children: [
        ],
        meta: {
          auth: {
            roles: ['admin']
          }
        }
      },
      {
        path: 'studies',
        name: 'study_dashboard',
        component: loadView('pages/StudiesDashboard'),
        meta: {
          auth: {
            roles: ['admin', 'study_user']
          }
        }
      },
      {
        path: 'study',
        name: 'study',
        component: loadView('pages/Study'),
        meta: {
          auth: {
            roles: ['admin', 'study_user']
          }
        },
        children: [
          {
            path: 'studydetail',
            name: 'studydetail',
            component: loadView('components/study/StudyDetail'),
          },
          {
            path: 'setting',
            name: 'study_setting',
            component: loadView('components/study/Setting'),
          },
        ]
      },
      {
        path: 'knowledgebase',
        name: 'knowledgebase',
        component: loadView('pages/Knowledgebase'),
        meta: {
          auth: {
            roles: ['admin', 'kb_user']
          }
        }
      },
      {
        path: 'patient/:id',
        component: loadView('pages/Patient'),
        meta: {
          auth: {
           roles: ['admin', 'dacy_user', 'survey_user']
          }
        },
        children: [
          {
            path: 'ecog',
            name: 'ecog',
            component: loadView('components/patient/PatientEcog'),
          },
          {
            path: 'therapy',
            name: 'therapy',
            component: loadView('components/patient/PatientTherapy'),
          },
          {
            path: 'therapyfromdrug',
            name: 'therapyfromdrug',
            component: loadView('components/patient/PatientTherapyFromDrug'),
          },
          {
            path: 'finding',
            name: 'finding',
            component: loadView('components/patient/PatientFindings'),
          },
          {
            path: 'sideeffects',
            name: 'sideeffects',
            component: loadView('components/patient/PatientSideEffectForm'),
          },
          {
            path: 'sideeffects/:patient_sideeffect_id',
            name: 'edit_sideeffect',
            component: loadView('components/patient/PatientSideEffectForm'),
          },
          {
            path: 'diagnosis',
            name: 'diagnosis',
            component: loadView('components/patient/PatientDiagnosisForm'),
          },
          {
            path: 'diagnosis/:patient_diagnosis_id',
            name: 'edit_diagnosis',
            component: loadView('components/patient/PatientDiagnosisForm'),
          },
          {
            path: 'progress',
            name: 'progress',
            component: loadView('components/patient/PatientProgresses'),
          },
          {
            path: 'therapyconcept',
            name: 'therapyconcept',
            component: loadView('components/patient/PatientTherapyConcept'),
          },
          {
            path: 'filecard',
            name: 'filecard',
            component: loadView('components/patient/PatientFileCard'),
          },
        ]
      },
    ]
  },
  {
    path: '/splash',
    name: 'splash',
    component: loadView('Splash'),
  },
  {
    path: '/kb',
    name: 'public_kb',
    component: loadView('PublicKnowledgebase'),
  },
  {
    path: '/client-settings',
    name: 'client-settings',
    component: loadView('ClientSettings'),
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('Login')
  }
]

let router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(() => {

})



export default router
